@import 'intl-tel-input/build/css/intlTelInput.css';

@font-face {
    font-family: "Maersk Text";
    font-style: normal;
    font-weight: 300;
    src: url("~/fonts/MaerskText-Light.woff2") format("woff2");
}
@font-face {
    font-family: "Maersk Text";
    font-style: italic;
    font-weight: 300;
    src: url("~/fonts/MaerskText-LightItalic.woff2") format("woff2");
}
@font-face {
    font-family: "Maersk Text";
    font-style: normal;
    font-weight: normal;
    src: url("~/fonts/MaerskText-Regular.woff2") format("woff2");
}
@font-face {
    font-family: "Maersk Text";
    font-style: italic;
    font-weight: normal;
    src: url("~/fonts/MaerskText-Italic.woff2") format("woff2");
}
@font-face {
    font-family: "Maersk Text";
    font-style: normal;
    font-weight: bold;
    src: url("~/fonts/MaerskText-Bold.woff2") format("woff2");
}
@font-face {
    font-family: "Maersk Text";
    font-style: italic;
    font-weight: bold;
    src: url("~/fonts/MaerskText-BoldItalic.woff2") format("woff2");
}
@font-face {
    font-family: "Maersk Headline";
    font-style: normal;
    font-weight: 300;
    src: url("~/fonts/MaerskHeadline-Light.woff2") format("woff2");
}
@font-face {
    font-family: "Maersk Headline";
    font-style: italic;
    font-weight: 300;
    src: url("~/fonts/MaerskHeadline-LightItalic.woff2") format("woff2");
}
@font-face {
    font-family: "Maersk Headline";
    font-style: normal;
    font-weight: normal;
    src: url("~/fonts/MaerskHeadline-Regular.woff2") format("woff2");
}
@font-face {
    font-family: "Maersk Headline";
    font-style: italic;
    font-weight: normal;
    src: url("~/fonts/MaerskHeadline-Italic.woff2") format("woff2");
}
@font-face {
    font-family: "Maersk Headline";
    font-style: normal;
    font-weight: bold;
    src: url("~/fonts/MaerskHeadline-Bold.woff2") format("woff2");
}
@font-face {
    font-family: "Maersk Headline";
    font-style: italic;
    font-weight: bold;
    src: url("~/fonts/MaerskHeadline-BoldItalic.woff2") format("woff2");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    [hidden] {
        @apply !hidden;
    }
}

@layer components {
    [data-component="content.step"] + [data-component="content.step"] {
        @apply !pt-0;
    }
    .prose iframe:is(
        [src^="https://www.youtube.com/embed/"],
        [src^="https://www.youtube-nocookie.com/embed/"],
        [src^="https://player.vimeo.com/video/"]
    ) {
        @apply aspect-video w-full h-auto my-6 first:mt-0 last:mb-0;
    }
}
